import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getItem } from "Utils/storage";
import { message } from "antd";

const Private = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!getItem("token")) {
        return (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        );
      } else {
        const restrictedPaths = ["ROLE", "USER"];
        let allow = true;
        // replace for not true to check for null only
        restrictedPaths.forEach(path => {
          if (
            allow &&
            rest.location.pathname.toLowerCase().includes(path.toLowerCase()) &&
            getItem(path) === null
          ) {
            allow = false;
          }
        });
        // need to wait for it
        if (allow) {
          return <Component {...props} />;
        } else {
          message.error("Path not permitted");
          return (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          );
        }
      }
    }}
  />
);

export default Private;
