import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, Observable } from "apollo-link";
import Mutation from "./Mutation";
import defaults from "./Defaults";
import { getItem, removeItem } from "../Utils/storage";
import { onError } from "apollo-link-error";
import { message } from "antd";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.log("message", message);
      // message.error(message?.split(":")?.pop() || "");
      // if (message.includes("AuthenticationError")) {
      //   removeItem("token");
      // }
      return message;
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const request = async operation => {
  operation.setContext({
    headers: {
      token: getItem("token")
    }
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_HTTP_LINK_URI
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HTTP_LINK_URI
});
const stateLink = withClientState({
  cache: new InMemoryCache(),
  defaults,
  resolvers: {
    Mutation
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    stateLink,
    requestLink,
    uploadLink,
    httpLink
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: true
});

export default client;
