import { getSidebar } from "Tags/sidebar/sidebar.gql";

const ToggleSidebar = (_obj, _arg, { cache }) => {
  const previous = cache.readQuery({ query: getSidebar });
  const data = {
    ...previous,
    sidebar: {
      ...previous.sidebar,
      isOpen: !previous.sidebar.isOpen
    }
  };
  cache.writeData({ data });
  return null;
};

export default {
  ToggleSidebar
};
