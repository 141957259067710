import React from "react";
import { Spin } from "antd";
import { Route, Switch, Router } from "react-router-dom";

import Private from "./Hoc/Private";

import Loadable from "react-loadable";

const Loading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin size="large" />
    </div>
  );
};
// components
const Login = Loadable({
  loader: () => import("./Components/Login/Login"),
  loading: () => <Loading />
});

const AppLayout = Loadable({
  loader: () => import("./Components/AppLayout/AppLayout"),
  loading: () => <Loading />
});
const Transcripts = Loadable({
  loader: () => import("./Components/Transcripts/Transcripts"),
  loading: () => <Loading />
});
const NotFoundPage = Loadable({
  loader: () => import("./Components/404Page/404Page"),
  loading: () => <Loading />
});
const StudyList = Loadable({
  loader: () => import("./Components/StudyList/StudyList"),
  loading: () => <Loading />
});

const WorkSheetTable = Loadable({
  loader: () => import("./Components/NewWorkSheet/WorkSheetPage"),
  loading: () => <Loading />
});
const Step2 = Loadable({
  loader: () => import("./Components/NewStep2/Step2"),
  loading: () => <Loading />
});

const User = Loadable({
  loader: () => import("./Components/User/User"),
  loading: () => <Loading />
});

const Role = Loadable({
  loader: () => import("./Components/Role/Role"),
  loading: () => <Loading />
});
const SearchWorksheet = Loadable({
  loader: () => import("./Components/NewWorkSheet/SearchWorksheet"),
  loading: () => <Loading />
});

const ImportExcel = Loadable({
  loader: () => import("./Components/ImportExcel/ImportExcel"),
  loading: () => <Loading />
});
const SearchStudy = Loadable({
  loader: () => import("./Components/SearchStudy/SearchStudy"),
  loading: () => <Loading />
});
const OldStudyWorksheet = Loadable({
  loader: () => import("./Components/OldStudyWorksheet/OldStudyWorksheet"),
  loading: () => <Loading />
});
export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Private component={AppLayout} />
    </Switch>
  );
};

export const ContentRoute = () => {
  return (
    <Switch>
      <Route exact path="/" component={StudyList} />
      <Route exact path="/importstudy" component={ImportExcel} />
      <Route exact path="/searchstudy" component={SearchStudy} />
      <Route
        exact
        path="/worksheet/:study_id/:category_id"
        component={WorkSheetTable}
      />
      <Route
        exact
        path="/oldworksheet/:study_id/:category_id"
        component={OldStudyWorksheet}
      />
      <Route
        exact
        path="/worksheet/:study_id/:category_id/:search_val"
        component={SearchWorksheet}
      />
      <Route exact path="/study/:study_id" component={Transcripts} />
      <Route exact path="/role" component={Role} />
      <Route
        exact
        path="/study/:study_id/transcript/:transcript_id"
        component={Step2}
      />
      <Route exact path="/user" component={User} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};
export default Routes;
