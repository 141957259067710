import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter } from "react-router-dom";
import client from "./Apollo";
import Routes from "./routes";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { toJS } from "mobx";
import "./index.scss";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <DragDropContextProvider backend={HTML5Backend}>
        <Routes />
      </DragDropContextProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

if (process.env.NODE_ENV === "development") {
  window.console = (function(oldCons) {
    return {
      ...oldCons,
      log: function(...args) {
        const newArgs = args.map(param => {
          return toJS(param);
        });
        oldCons.log(...newArgs);
      }
    };
  })(window.console);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
